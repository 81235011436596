/* Global */
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;500;700&family=Montserrat:wght@300;400;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    /*scroll-behavior: smooth;*/
}

body {
    position: relative;
    background: #000;
}

h1, h2, h3, h4, h5, h6, button {
    font-family: 'Cormorant', serif;
    color: #fff;
}

li, a, p, section,
.topBar__language__inner,
.heroCarousel__subheader
{
    font-family: 'Montserrat', sans-serif;
    color: #fff !important;
}

button, a {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

a {
    text-decoration: none;
    color: inherit;
}

html, body {
    overflow-x: hidden;
}

.container {
    width: 100%;
    overflow-x: hidden;
}

.d-mobile {
    display: none;
}

:root {
    --gold: #B56437;
    --dark: #1E1E1E;
    --siteWidth: clamp(200px, 85vw, 2300px);
}

/* Hero loader */
.heroLoader {
    width: 100%;
    height: 100vh;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    transition: 2s all;
}

.heroLoader__img {
    transform: scale(.4);
    max-width: 100%;
    transition: 20s all;
}

/* Hero section */
.heroCarousel {
    display: none;
    opacity: 0;
    max-height: 100vh;
    transition: 2s all ease-in-out;
    min-height: 600px;
    position: relative;
}

.heroCarousel__content {
    width: var(--siteWidth);
    margin: auto;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
}

.heroCarousel__buttons {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2;
    opacity: .5;
}

.heroCarousel__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heroCarousel__button {
    width: 30px;
    height: 30px;
    background: transparent;
    border: 2px solid var(--gold);
    border-radius: 50%;
    margin-bottom: 15px;
    position: relative;
}

.heroCarousel__arrows {
    position: absolute;
    right: 0;
    bottom: 5%;
}

.heroCarousel__buttonArrow {
    background: transparent;
    border: none;
    outline: none;
    margin: 0 20px;
}

.heroCarousel__buttonArrow__arrow {
    width: 10px;
    height: auto;
}

.buttonArrow--next {
    transform: rotateY(180deg);
}

.heroCarousel__image {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.heroCarousel__image__img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    filter: brightness(.5);
    transition: 1.5s all ease;
}

#hero2, #hero3 {
    opacity: 0;
}

.heroCarousel__button--selected {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    background: var(--gold);
    border-radius: 50%;
}

/* Top bar */
.topBar {
    margin-top: 25px;
    width: 100%;
}

.container > .topBar, .container > .topMenu {
    width: var(--siteWidth);
    margin: auto;
}

.container > .topBar {
    margin-top: 20px;
}

.topBar__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.topBar__facebookLink, .topBar__shop {
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.topBar__facebookLink {
    opacity: .85;
    transition: .3s all ease-in-out;
}

.topBar__facebookLink:hover {
    opacity: 1;
}

.topBar__facebookLink__img {
    margin-right: 15px;
}

.topBar__logoLink__img {
    display: block;
    width: 570px;
    margin: 15px auto;
}

.topBar__bottom {
    position: relative;
}

.topBar__shop {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-transform: uppercase;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    opacity: .75;
    transition: .2s all;
}

.topBar__shop:hover {
    opacity: 1;
}

.topBar__shop__img {
    margin-left: 15px;
}

.topBar__language {
    font-size: 12px;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.topBar__language__inner {
    margin-left: 15px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
}

.topBar__language__inner--second {
    position: absolute;
    bottom: -45px;
    right: 0;
    z-index: 2;
    display: none;
    opacity: 0;
    border-bottom: none;
    transition: .5s all;
}

.topBar__language__inner--second--portfolio {
    cursor: pointer;
    background: #000;
    border: none;
    outline: none;
    z-index: 2;
    text-transform: uppercase;
}

@media(max-width: 996px) {
    .topBar__language__inner--second--portfolio {
        transform: translateX(-10px);
    }

    .topBar__language__inner--second--portfolio span {
        padding: 0 10px;
    }
}

.flag {
    width: 25px;
    height: auto;
}

.languageBtn {
    background: transparent;
    border: none;
    outline: none;
    margin-left: 10px;
}

.languageBtn__img {
    transition: .4s all;
}

.flag {
    margin-right: 10px;
}

/* Top menu */
.topMenu {
    border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.topMenu__list {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.topMenu__item {
    position: relative;
}

.topMenu__link {
    text-transform: uppercase;
    color: #fff;
    transition: .3s all;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.topMenu__link:hover {
    color: var(--gold) !important;
}

.dropdownMenuIcon {
    margin-left: 15px;
    transform: rotateX(180deg);
    transition: .4s all;
}

.topMenu__submenu {
    background: rgba(0, 0, 0, .56);
    list-style-type: none;
    position: absolute;
    top: calc(100% + 17px);
    left: 0;
    min-width: 250px;
    text-align: center;
    display: none;
    opacity: 0;
    padding: 0 40%;
    transition: .4s opacity ease;
}

.topMenu__submenu::before {
    content: "";
    background: transparent;
    z-index: 0;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 30px;
}

.topMenu__submenu:hover {
    display: block;
    opacity: 1;
}

.topMenu__submenu__item {
    padding: 12px 0;
}

.topMenu__submenu__item:not(:last-of-type) {
    border-bottom: 1px solid var(--gold);
}

.topMenu__submenu__link {
    font-size: 13px;
    transition: .3s all;
}

.topMenu__link:hover .topMenu__submenu {
    display: block;
    opacity: 1;
}

.topMenu__link:hover .dropdownMenuIcon {
    transform: none;
}

.topMenu__submenu__link:hover {
    color: var(--gold) !important;
}

/* Landing page */
.heroCarousel__header {
    font-size: 64px;
    margin-bottom: 25px;
    margin-top: 12%;
    font-weight: 400;
}

.heroCarousel__subheader {
    font-size: 22px;
    font-weight: 300;
}

.button {
    background: transparent;
    border: 1px solid var(--gold);
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .7s all ease-in-out;
}

.button--landing {
    height: 100px;
    width: 450px;
    margin-top: 50px;
    font-size: 32px;
    font-weight: 300;
    font-family: 'Cormorant', serif;
    position: relative;
    z-index: 9999;
}

/* Section header */
.sectionHeader {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 300;
    margin: 30px 0;
    padding-bottom: 14px;
    border-bottom: 1px solid #fff;
    position: relative;
}

.sectionHeader--right {
    text-align: right;
}

.sectionHeader::after {
    content: "";
    position: absolute;
    bottom: -1px;
    width: 150px;
    background: var(--gold);
    height: 2px;
}

.sectionHeader--left::after {
    left: 0;
}

.sectionHeader--right::after {
    right: 0;
}

.section {
    position: relative;
    z-index: 3;
    width: var(--siteWidth);
    margin: auto;
}

.section--aboutUs .sectionHeader::after {
    width: 75px;
}

.section--creationProcess .sectionHeader::after {
    width: 205px;
}

.section--portfolio .sectionHeader::after {
    width: 105px;
}

.section--contact .sectionHeader::after {
    width: 100px;
}

/* About us */
.aboutUs {
    position: relative;
    margin-top: 100vh;
}

.absolute {
    position: absolute;
}

.aboutUs__img--1 {
    left: calc((100vw - var(--siteWidth)) / 2 * (-1));
    top: 0;
}

.aboutUs__img--2 {
    right: 0;
    top: 10vh;
}

.aboutUs__content {
    margin-left: clamp(50px, 20%, 340px);
    padding-top: 5vh;
    max-width: 50%;
    padding-bottom: 5vh;
}

.aboutUs__header {
    font-size: 36px;
    margin-bottom: 30px;
    font-weight: 400;
}

.aboutUs__text {
    font-size: 17px;
    margin-bottom: 25px;
}

.button--aboutUs {
    height: 70px;
    width: 400px;
    margin-top: 50px;
    font-size: 24px;
    font-weight: 300;
    font-family: 'Cormorant', serif;
}

/* Technologies */
.section--technologies {
    margin-top: 200px;
    margin-bottom: 300px;
}

.technologies {
    position: relative;
    margin-top: 70px;
}

.img--technologies1 {
    top: 0;
    left: calc((100vw - var(--siteWidth)) / 2 * (-1));
}

.aboutUs__header--technologies {
    max-width: 400px;
    margin-left: clamp(200px, 35%, 700px);
}

.technology--1 {
    margin-left: clamp(200px, 35%, 700px);
    margin-top: 100px;
}

.technology {
    position: relative;
    max-width: 600px;
}

.technology__number {
    font-weight: 700;
    font-family: 'Cormorant', serif;
    font-size: 190px;
    line-height: 50px;
    color: #1E1E1E;
    position: absolute;
    top: 0;
    left: 0;
}

.technology__name {
    font-size: 48px;
    padding-top: 15px;
    margin-left: 50px;
    position: relative;
    z-index: 2;
}

.technology__desc {
    margin-top: 30px;
    margin-left: 50px;
    position: relative;
    z-index: 2;
}

.button--technology {
    height: 70px;
    width: 350px;
    margin-top: 40px;
    margin-left: 50px;
    font-size: 24px;
    font-weight: 300;
    font-family: 'Cormorant', serif;
}

.img--technologies2 {
    right: 0;
    top: 700px;
}

.img--technologies3 {
    left: 10%;
    top: 1200px;
}

.technology--2 {
    margin-left: 20%;
    margin-top: 300px;
}

.technology--3 {
    margin-left: 60%;
    margin-top: 300px;
}

/* Creation process */
.creationProcess {
    margin: 50px 0;
}

.creationProcess__desc {
    text-align: center;
    max-width: 900px;
    margin: 15px auto;
}

.button--process {
    height: 80px;
    width: 420px;
    text-align: center;
    margin: 40px auto 20px;
    font-size: 20px;
    font-weight: 300;
    font-family: 'Cormorant', serif;
}

/* Portfolio */
.portfolioSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#sectionPicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.portfolioItem {
    width: 30%;
    height: auto;
}

.portfolioItem__img {
    width: 100%;
    height: auto;
}

.button--portfolio {
    width: 450px;
}

/* Contact */
.contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0 100px;
}

.contact__map {
    width: 55%;
    height: 400px;
}

.contact__content {
    width: 40%;
}

.contact__header {
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 30px;
}

.contact__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.contact__item__icon {
    width: 30px;
    height: auto;
    margin-right: 20px;
}

.contact__item__text {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

#googleMap > div {
    position: relative !important;
}

/* Footer */
.footer {
    border-top: 1px solid #fff;
    padding: 40px 0 120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.footer__section {
    width: 30%;
}

.footerHeader {
    position: relative;
    font-size: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #fff;
    margin-bottom: 25px;
}

.footerHeader::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 30%;
    height: 2px;
    background: var(--gold);
}

.footerHeader--navigation::after {
    width: 100px;
}

.footerHeader--links::after {
    width: 60px;
}

.footerHeader--shop::after {
    width: 125px;
}

.footer__list {
    list-style-type: none;
}

.footer__list__item {
    margin: 10px 0;
}

.footer__list__item__link {
    font-size: 15px;
    transition: .3s all;
}

.footer__list__item__link:hover {
    color: var(--gold) !important;
}

.footer__list--flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.footer__list--flex .footer__list__item {
    width: 47%;
}

.footer__bottom {
    margin-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, .25);
    padding: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer__bottom__header {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.footer__bottom__header__link {
    color: #fff;
    opacity: .7;
    transition: .3s all;
}

.footer__bottom__header__link:hover {
    opacity: 1;
}

/* Button hover effect */
.button {
    position: relative;
    z-index: 5;
    background:transparent;
    background-position: 1% 100%;
    background-size: 300% 300%;
    background-image: linear-gradient(45deg, transparent 0%, transparent 75%, rgba(235,235,235,0.5) 75%, transparent 100%);
}

.button:hover {
    background-position:150% top;
}

@media(-webkit-min-device-pixel-ratio: 1.05) {
    .heroCarousel__header {
        font-size: 38px;
    }

    .heroCarousel__subheader {
        font-size: 21px;
    }

    .button--landing {
        transform-origin: top left;
    }

    @media(max-width: 996px) {
        .button--landing {
            transform-origin: top center;
        }
    }

    @media(min-width: 1200px) {
        .img--technologies3,
        .button--landing,
        .topBar__logoLink__img {
            transform: scale(.8) !important;
        }
    }

    .topMenu__link {
        font-size: 13px;
    }
}

/* Background - layers */
.background {
    position: absolute;
    z-index: 1;
}

.background--1 {
    top: 50vh;
    left: -240px;
}

.background--2 {
    right: -300px;
    top: 120vh;
}

.background--3 {
    top: 220vh;
    left: -300px;
}

.background--4 {
    top: 2500px;
    right: -300px;
}

/* Witraze page */
.pageHeader {
    text-align: center;
    margin: 70px auto 50px;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--gold);
    padding-bottom: 10px;
    width: fit-content;
    width: -moz-max-content;
}

.pageContent__witraze {
    margin: 60px auto 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: clamp(200px, 60vw, 2000px);
}

.pageContent__witraze__section {
    position: relative;
    width: 45%;
    overflow: hidden;
    height: 450px;
    border: 1px solid rgba(255, 255, 255, .75);
}

.pageContent__witraze__img {
    width: 100%;
}

.pageContent__witraze__section__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, .8);
    padding: 10%;
}

.pageContent__witraze__section__header {
    text-align: center;
    font-size: 28px;
}

.pageContent__witraze__section__desc {
    text-align: center;
    margin: 25px 0 40px;
    font-size: 13px;
}

.button--witraze {
    width: 250px;
    height: 75px;
    border-color: #B56437;
}

.pageContent__witrazeGrid {
    width: var(--siteWidth);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 50px auto;
}

/* Witraze subpage */
.pageContent__witrazeGrid__item {
    width: 30%;
    height: 450px;
    margin-bottom: 40px;
    overflow: hidden;
    position: relative;
    border: 1px solid rgba(255, 255, 255, .8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.pageContent__witrazeGrid__item .pageContent__witraze__section__content {
    opacity: 0;
    transition: .5s all ease-in-out;
}

.pageContent__witrazeGrid__item .pageContent__witraze__section__content:hover {
    opacity: 1;
}

/* References */
.pageContent__witrazeGrid--references .pageContent__witrazeGrid__item {
    height: auto;
}

.pageContent__witrazeGrid--references .pageContent__witraze__img {
    width: 100%;
    height: auto;
}

/* Art gallery */
.pageContent__artGallery {
    position: relative;
    margin-bottom: 220px;
}

.pageContent__artGallery__desc {
    position: absolute;
    top: 20%;
    left: clamp(100px, 40%, 900px);
    width: 40%;
}

.pageContent__artGallery__desc p {
    margin-top: 15px;
}

.pageContent__artGallery__header {
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 30px;
}

.pageContent__artGallery__images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 120%;
    margin: 90px 0;
}

.pageContent__artGallery__images__img {
    width: 30%;
    height: auto;
}

/* About us */
.aboutUs__section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 80px;
}

.aboutUs__section--end {
    justify-content: flex-end;
}

.aboutUs__section--center {
    justify-content: center;
}

.aboutUs__section__header {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 50px;
}

.aboutUs__section__content {
    width: 45%;
    margin-left: 50px;
}

.aboutUs__section__content p {
    margin: 30px 0;
}

.aboutUs__section--end .aboutUs__section__content {
    margin-right: 80px;
}

.aboutUs__section--end .aboutUs__section__header,
.aboutUs__section--end .aboutUs__section__content p {
    text-align: right;
}

.aboutUs__section__buttons {
    display: flex;
    align-items: center;
    margin-top: -30px;
}

.aboutUs__section__buttons .button--aboutUs {
    width: 300px;
    height: 60px;
    font-size: 20px;
    margin-right: 30px;
}

.aboutUs__section__img--1 {
    width: 30%;
    height: auto;
}

.aboutUs__section__img--2 {
    width: 35%;
    height: auto;
}

.aboutUs__section__img--3 {
    width: 25%;
    height: auto;
}

/* Technologies */
.technologies__section {
    width: var(--siteWidth);
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.technologies__section .aboutUs__section__content {
    width: 100%;
}

.aboutUs__section__content--marginRight {
    margin-right: 50px;
    margin-left: 0;
}

.pageContent--technologies {
    margin-bottom: 100px;
}

.technologies__section__img {
    width: 50%;
}

/* Privacy policy and terms of service */
.pageContent__article {
    width: var(--siteWidth);
    margin: 40px auto;
}

.pageContent__article p {
    margin: 20px 0;
    text-align: justify;
}

/* Top bar shop */
.topBarShop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: var(--siteWidth);
    margin: 0 auto 20px;
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, .25);
}

.topBarShop__header {
    font-weight: 400;
}

.topBarShop__header a {
    font-family: 'Cormorant', serif;
    opacity: .9;
}

.topBarShop__header a:hover {
    opacity: 1;
}

.topBarShop__cart {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.topMenuShopMobile {
    width: var(--siteWidth);
    margin: 20px auto;
    justify-content: space-between;
    align-items: center;
}

.topMenuShopMobile .topMenu__mobile {
    width: 100%;
}

/* Shop */
.shop {
    width: var(--siteWidth);
    margin: 30px auto 150px;
}

.shop__top {
    width: var(--siteWidth);
    margin: 10px auto 30px;
}

.shop__top .shop__top__link {
    color: rgba(255, 255, 255, .5) !important;
    font-size: 13px;
    text-decoration: underline;
    transition: .3s all ease;
}

.shop__top .shop__top__link:hover {
    color: rgba(255, 255, 255, .8) !important;
}

.shop__main {
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.shop__categories {
    flex-basis: 20%;
    border: 1px solid var(--gold);
}

.shop__products {
    flex-basis: 75%;
}

.shop__categories__header {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    padding: 15px 0;
    margin-bottom: 15px;
}

.shop__categories__header a {
    font-family: 'Cormorant', serif;
}

.shop__categories__list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shop__categories__list__item {
    width: 80%;
    text-align: center;
    padding: 25px 0;
    border-bottom: 1px solid rgba(255, 255, 255, .25);
}

.shop__categories__list__item__link {
    transition: .3s all ease-in-out;
}

.shop__categories__list__item__link:hover {
    color: var(--gold) !important;
}

.shop__products__header {
    font-size: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, .25);
    padding-bottom: 10px;
    font-weight: 400;
}

.shop__products__products {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.shop__products__singleProduct {
    width: 23%;
    margin: 30px 0;
}

.shop__products__singleProduct__imgWrapper {
    width: 100%;
    height: 350px;
    overflow: hidden;
}

.shop__products__singleProduct__img {
    min-width: 100%;
    min-height: 100%;
    max-width: 110%;
    max-height: 110%;
    transition: .5s all ease-in-out;
}

.shop__products__singleProduct__title {
    font-weight: 400;
    text-align: center;
    font-size: 28px;
    margin: 25px 0 15px;
}

.shop__products__singleProduct__price {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: var(--gold);
    font-family: 'Montserrat', sans-serif;
}

.button--addToCart {
    margin: 25px auto;
    width: 100%;
    height: 60px;
}

.shop__products__singleProduct:hover .shop__products__singleProduct__img {
    filter: brightness(.5);
}

/* Single product */
.single {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: var(--siteWidth);
    margin: 40px auto;
}

.single__product {
    width: 75%;
}

.dedicationInput {
    width: 100%;
    height: 100px;
    padding: 15px;
    font-family: 'Montserrat', sans-serif;
    color: #efefef;
    resize: none;
    background: transparent;
    border: 1px solid var(--gold);
}

.shop__products__header {
    position: relative;
}

.shop__products__header__price {
    color: var(--gold);
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    right: 0;
    top: 0;
}

.single__product__main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 40px 0;
}

.single__product__main__imgWrapper {
    width: 35%;
    height: 500px;
    overflow: hidden;
    position: relative;
}

.single__product__main__imgWrapper img {
    /*width: 100% !important;*/
}

.single__product__main__imgOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    opacity: 0;
    transition: .4s all ease-in-out;
}

.single__product__main__imgWrapper:hover .single__product__main__imgOverlay {
    opacity: 1;
}

.single__product__main__imgOverlay__img {
    width: 40px;
    height: auto;
}

.single__product__main__imgWrapper img {
    min-width: 100%;
    min-height: 100%;
}

.single__product__main__content {
    width: 60%;
}

.single__product__main__spec {
    padding: 30px;
    background: #141414;
}

.single__product__main__spec__header {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding-bottom: 10px;
    font-size: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    margin-bottom: 30px;
}

.single__product__main__spec__desc {
    font-size: 14px;
}

.single__product__main__spec__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, .25);
    margin-bottom: 20px;
}

.single__product__main__spec__key {
    color: rgba(255, 255, 255, .55);
}

.single__product__main__spec__header:last-of-type {
    margin-top: 40px;
}

/* Cart */
.cart {
    margin: 40px auto 100px;
    width: var(--siteWidth);
}

.cart__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, .25);
    margin: 20px 0;
}

.cart__item__imgWrapper {
    width: 200px;
    height: 200px;
    overflow: hidden;
}

.cart__item__img {
    min-width: 100%;
    min-height: 100%;
    max-width: 200%;
}

.cart__item__prop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cart__item__key,
.cart__item__value {
    text-align: center;
}

.cart__item__key {
    font-size: 14px;
    color: rgba(255, 255, 255, .5);
    padding-bottom: 15px;
}

.cart__item__value {
    font-size: 16px;
}

.cart__item__value--trash {
    background: transparent;
    border: none;
    outline: none;
    opacity: .75;
    transition: .2s all;
}

.cart__item__value--trash:hover {
    opacity: 1;
}

.cart__sum {
    font-size: 32px;
    font-weight: 400;
    text-align: right;
    margin: 10px 0 30px;
}

.cart__sum__price {
    padding-left: 25px;
    font-family: 'Montserrat', sans-serif;
    color: var(--gold);
}

.cart__item__value--price {
    color: var(--gold);
    font-size: 18px;
}

.cart__item__value input {
    border: none;
    background: transparent;
    width: 25px;
    height: 30px;
    color: #fff;
}

.cart__item__value--input {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--gold);
    padding-bottom: 8px;
}

/* Remove arrows from number input type - Chrome, Safari, Edge, Opera */
.cart input::-webkit-outer-spin-button,
.cart input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.cart__item__input__btn {
    background: transparent;
    border: none;
    outline: none;
    width: 8px;
    height: auto;
}

.cart__item__input__btn__img {
    width: 100%;
    height: auto;
}

.cart__item__input__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cart__item__input__btn:last-of-type .cart__item__input__btn__img {
    transform: rotateX(180deg);
}

.cart__item__mobileRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart__item__mobileRow > * {
    margin: 0 5vw;
}

/* Shipping and payment */
.shippingAndPayment {
    width: var(--siteWidth);
    margin: 50px auto;
}

.error {
    display: block;
    color: red;
    font-size: 13px;
    padding-bottom: 30px;
}

.label--checkbox {
    font-size: 13px;
    margin-top: 50px;
}

.label--checkbox input {
    margin-right: 10px;
}

.label--checkbox a {
    opacity: .9;
    font-size: 13px;
}

.label--checkbox a:hover {
    opacity: 1;
}

.shippingAndPayment__sum {
    font-weight: 400;
    font-size: 26px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.shippingAndPayment__sum__price {
    padding-left: 30px;
    font-weight: 400;
    color: var(--gold);
    padding-top: 5px;
}

.shippingAndPayment__form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.shippingAndPayment__form__section {
    width: 45%;
    margin-top: 40px;
}

.shippingAndPayment__smallHeader {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    margin-bottom: 20px;
}

.shippingAndPayment__dataForm {
    width: 100%;
}

.input {
    background: #131313;
    border: 1px solid var(--gold);
    height: 100%;
    padding: 0 15px;
    width: 100%;
    color: #fff;
}

.input--error {
    border-color: red;
}

.input::placeholder {
    color: rgba(255, 255, 255, .5);
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shippingAndPayment__form label {
    display: block;
    height: 50px;
    margin-bottom: 20px;
}

.label--name {
    width: 38%;
}

.label--surname {
    width: 60%;
}

.label--postalCode {
    width: 33%;
}

.label--city {
    width: 65%;
}

.label--street {
    width: 50%;
}

.label--building, .label--flat {
    width: 23%;
}

.shippingAndPayment__form .label--textarea {
    height: auto;
}

.input--textarea {
    padding: 15px;
    height: 150px;
    resize: none;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

.label--button {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    height: 25px !important;
    margin: 0;
    cursor: pointer;
}

.button--check {
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid var(--gold);
    border-radius: 50%;
    margin-right: 15px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.shippingAndPayment__smallHeader--marginTop {
    margin-top: 50px;
}

.button--shippingAndPayment {
    margin-top: 0;
}

.button--checked {
    display: block;
    width: 50%;
    height: 50%;
    background: var(--gold);
    border-radius: 50%;
}

/* Add to cart modal */
.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    opacity: 0;
    transition: .6s opacity;
}

.addToCartModal {
    position: relative;
    z-index: 10000;
    width: 70%;
    max-width: 800px;
    height: 500px;
    background: #131313;
    border: 1px solid #B56437;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.addToCartModal__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    background: transparent;
    border: none;
    outline: none;
}

.addToCartModal__close__img {
    width: 100%;
}

.addToCartModal__cartIcon {
    width: 200px;
    height: auto;
    margin-top: -20px;
}

.addToCartModal__header {
    text-align: center;
    font-weight: 400;
    font-size: 17px;
    margin: 25px 0 45px;
    font-family: 'Montserrat', sans-serif;
}

.addToCartModal__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button--addToCartModal {
    width: 300px;
    height: 50px;
    margin: 0 15px;
    font-family: 'Montserrat', sans-serif;
}

/* Empty cart */
.emptyCart__header {
    margin: 50px 0 20px;
    font-size: 24px;
    font-weight: 400;
}

.button--emptyCart {
    margin-top: 50px;
    width: 300px;
    height: 50px;
}

/* Ty page */
.ty {
    padding: 70px 20px;
}

.ty__header, .ty__subheader {
    text-align: center;
    font-weight: 400;
    font-size: 28px;
}

.ty__subheader {
    margin: 15px 0 40px;
}

.button--ty {
    width: 400px;
    height: 60px;
    margin: 20px auto;
}

/* Admin */
.loginPage .label {
    color: #fff;
}

.input--adminLogin {
    height: 40px;
}

.button--loginForm {
    font-family: 'Open sans', sans-serif;
    font-weight: 400;
}

.button--loginForm:hover {
    letter-spacing: 0 !important;
}

/* Pages */
article.pageContent__article * {
    color: #fff !important;
    background: transparent !important;
}

.d-900 {
    display: none;
}

.ue {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 100;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ue .img {
    height: 60px;
    width: auto;
    margin: 0 20px;
}

@media(max-width: 768px) {
    .ue {
        height: 60px;
    }

    .ue .img {
        height: 20px;
        width: auto;
        margin: 0 10px;
    }
}
